/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState, useRef } from 'react'
import { goPage, sniff } from '@/util'
import { Swiper } from 'antd-mobile'
import { Swiper as Swiper1, SwiperSlide } from 'swiper/react/swiper-react.js'
import { Autoplay, Pagination, Navigation } from 'swiper'
import 'swiper/swiper-bundle.min.css'
import { Player, BigPlayButton, ControlBar } from 'video-react'
import 'video-react/dist/video-react.css' // import css

import './Swiper.scss'
import next from './images/next.png'
import prev from './images/prev.png'
import sound from './images/sound.png'
import soundNo from './images/sound-no.png'

const Swiper_ = (props) => {
  const {
    imgList = [],
    extraClass = '',
    banner_video = '',
    allImgLoad,
    hoverScale = false,
  } = props
  const swiperRef = useRef(null)
  const playerRef = useRef(null)
  const [isMuted, setIsMuted] = useState(true)
  const [isAutoPlay, setIsAutoPlay] = useState(false)

  const changeSwiper = (type) => {
    if (type === 'next') {
      swiperRef.current.slideNext()
    } else {
      swiperRef.current.slidePrev()
    }
  }

  const swiperClickHandler = (v) => {
    const { link = '' } = v
    if (!link) return
    if (link.indexOf('http' !== -1)) {
      window.open(link)
    } else {
      goPage(link)
    }
  }

  useEffect(() => {
    const imgArr = []
    imgList.forEach((v) => {
      imgArr.push(v.image || '')
    })
    const promiseAll = [],
      imgs = [],
      total = imgArr.length
    for (let i = 0; i < total; i++) {
      promiseAll[i] = new Promise((resolve) => {
        imgs[i] = new Image()
        imgs[i].src = imgArr[i]
        imgs[i].onload = () => {
          resolve(imgs[i])
        }
      })
    }
    Promise.all(promiseAll).then(() => {
      allImgLoad && allImgLoad()
    })
  }, [imgList])

  useEffect(() => {
    if (playerRef.current) {
      const isMobile = sniff.isMobile
      const vs = document.getElementsByTagName('video')
      let targetVideo = vs[0]
      if (!isMobile) targetVideo = vs[1]
      targetVideo.oncanplaythrough = () => {
        targetVideo.play()
      }
    }
  }, [banner_video, playerRef])

  return (
    <>
      <div className="mobile-container">
        <Swiper
          autoplay
          className={`mobile-swiper-container ${extraClass}`}
          loop
        >
          {!banner_video &&
            imgList.map((v, key) => (
              <Swiper.Item
                key={key}
                onClick={() => {
                  swiperClickHandler(v)
                }}
              >
                <div className="img-container">
                  <img src={v.image} />
                </div>
              </Swiper.Item>
            ))}

          {banner_video && (
            <Swiper.Item>
              <Player
                ref={(c) => {
                  playerRef.current = c
                }}
                autoPlay={false}
                preload="auto"
                muted={isMuted}
                loop
                poster={imgList[0]?.image}
              >
                <source src={banner_video} />
                <BigPlayButton position={'center'} />
                <ControlBar autoHide={false} className="my-ControlBarclass" />
              </Player>
              {/* <img
                src={isMuted ? soundNo : sound}
                className="video-sound-btn"
                onClick={() => {
                  setIsMuted(!isMuted)
                }}
              /> */}
            </Swiper.Item>
          )}
        </Swiper>
      </div>

      <div className="pc-container">
        <div className="pc-swiper-container">
          <Swiper1
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            centeredSlides
            initialSlide={0}
            loop={imgList.length > 1}
            modules={[Autoplay, Pagination, Navigation]}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            pagination={{
              clickable: true,
            }}
            preloadImages
            slidesPerView={1}
            spaceBetween={16}
            updateOnImagesReady
          >
            {!banner_video ? (
              <>
                {imgList.map((v, key) => {
                  return (
                    <SwiperSlide
                      className="theme-swiper-item"
                      key={key}
                      onClick={() => {
                        swiperClickHandler(v)
                      }}
                    >
                      <div className="img-container">
                        <img
                          src={v.image}
                          className={hoverScale ? 'hoverScale' : ''}
                        />
                      </div>
                    </SwiperSlide>
                  )
                })}
              </>
            ) : (
              <>
                <SwiperSlide>
                  <Player
                    ref={(c) => {
                      playerRef.current = c
                    }}
                    preload="auto"
                    autoPlay={false}
                    muted={isMuted}
                    loop
                    poster={imgList[0]?.image}
                  >
                    <source src={banner_video} />
                    <BigPlayButton position={'center'} />
                    <ControlBar autoHide={false} className="my-class" />
                  </Player>
                  {/* <img
                    src={isMuted ? soundNo : sound}
                    className="video-sound-btn"
                    onClick={() => {
                      setIsMuted(!isMuted)
                    }}
                  /> */}
                </SwiperSlide>
              </>
            )}
          </Swiper1>
        </div>
      </div>
    </>
  )
}

export default Swiper_
