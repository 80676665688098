import Server from '@/Api/server'

const server = new Server()
const api = {
  post: (url, payload, config = {}) => {
    if (config?.structured === undefined) {
      config.structured = true
    }
    const dataAxios = server.axios(
      'post',
      url,
      { data: payload },
      { ...config }
    )
    return dataAxios
  },
  get: (url, payload, config = {}) => {
    if (config?.structured === undefined) {
      config.structured = true
    }
    const dataAxios = server.axios('get', url, { data: payload }, { ...config })
    return dataAxios
  },
}

export default api
