import React from 'react'
import { Router, Route, Redirect, Switch } from 'react-router-dom'

import queryString from 'query-string'

import routes from './routers'
import Login from '@/pages/login/login'
import { history } from '@/util'

// 本站国际化
import i18n from './i18next-config'

// components
import MainLayout from '@/components/MainLayout'

// antd-mobile引入全局逻辑和样式
import 'antd-mobile/es/global'
// 样式
import './App.scss'

function App() {
  const searchObj = queryString.parse(location.search) ?? {}
  const lang = searchObj?.lang

  // 本站国际化
  i18n(lang)
  return (
    <Router history={history}>
      <Switch>
        <Route path="/main" Route>
          <MainLayout routes={routes}></MainLayout>
        </Route>

        {/* 共用 */}
        <Route component={Login} path="/login/"></Route>
        <Redirect from="/" to="/main/index"></Redirect>
      </Switch>
    </Router>
  )
}

export default App
