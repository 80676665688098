import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import querystring from 'query-string'
import { useScroll } from 'ahooks'
import { goPage, scrollToAp, sniff } from '@/util'
import { Toast } from 'antd-mobile'

import './Header.scss'
import More from './images/more.png'
import LogoWhite from './images/logo-white.png'
import Close from './images/close.png'
import CloseBlack from './images/close-black.png'

const Header = (props) => {
  const appData = JSON.parse(localStorage.getItem('appData')) || {}
  const { nav_menus = [], site_info = {} } = appData
  const [mobileMenuSel, setMobileMenuSel] = useState(nav_menus[0])

  const location = useLocation()

  const [isMenuMoreShow, setIsMenuMoreShow] = useState(false) // 是否展示更多菜单
  const [pathName, setPathName] = useState('/main/index')
  const [hoverMenu, setHoverMenu] = useState('')

  const switchPage = (url, moreShow, hideChildren) => {
    if (url) goPage('/main' + url)
    if (moreShow) setIsMenuMoreShow(!isMenuMoreShow)
    if (hideChildren) setHoverMenu('')
    if (url.indexOf('ap') !== -1) {
      const pageName = url.split('?')[0].split('/')[1]
      pageName && scrollToAp(pageName)
    }
  }

  // 监听页面滚动
  const [headerClassName, setHeaderClassName] = useState('header-container')
  const scrollDoc = useScroll(document)
  useEffect(() => {
    if (!scrollDoc) return
    if (scrollDoc.top >= 600) {
      setHeaderClassName('header-container bgcw')
    } else {
      setHeaderClassName('header-container')
    }
  }, [scrollDoc])

  // 监听页面路由
  useEffect(() => {
    const _pathName = location.pathname || ''
    setPathName(_pathName)
  }, [location.pathname])

  useEffect(() => {
    document.body.style.overflow = isMenuMoreShow ? 'hidden' : 'auto'
  }, [isMenuMoreShow])

  const Logo = sniff.isMobile ? site_info.wap_logo : site_info.pc_logo

  return (
    <div className={headerClassName}>
      <div className="header-main">
        <div className="header-main-left">
          <img
            src={Logo}
            className="header-main-left-logo"
            onClick={() => {
              goPage('/')
            }}
          />
          {nav_menus &&
            nav_menus.length > 0 &&
            nav_menus.map((v, key) => {
              return (
                <div
                  key={key}
                  className="tab-item"
                  onMouseEnter={() => {
                    setHoverMenu(v?.path)
                  }}
                  onMouseLeave={() => {
                    setHoverMenu('')
                  }}
                >
                  <span
                    className={
                      pathName === '/main' + v.path ? 'tab-item-active' : ''
                    }
                    onClick={() => {
                      switchPage(v.path)
                    }}
                  >
                    {v.name}
                  </span>
                  {v?.path === hoverMenu && (
                    <div
                      className="tab-item-children"
                      onMouseEnter={() => {
                        setHoverMenu(v?.path)
                      }}
                      onMouseLeave={() => {
                        setHoverMenu('')
                      }}
                    >
                      {v?.children &&
                        v?.children?.length > 0 &&
                        v?.children.map((v1, key1) => {
                          return (
                            <div
                              key={key1}
                              className="tab-item-children-item"
                              onClick={() => {
                                switchPage(v1?.path, false, true)
                              }}
                            >
                              {v1?.name}
                            </div>
                          )
                        })}
                    </div>
                  )}
                </div>
              )
            })}
        </div>
        <img
          src={More}
          className="header-main-right-more"
          onClick={() => {
            setIsMenuMoreShow(!isMenuMoreShow)
          }}
        />
      </div>
      {isMenuMoreShow && (
        <>
          <div className="header-main-menus-pc">
            <div className="header-main-menus-container">
              <img src={LogoWhite} className="header-main-menus-logo" />
              <div className="header-main-menus-content">
                {nav_menus &&
                  nav_menus.length > 0 &&
                  nav_menus.map((v, key) => {
                    return (
                      <div key={key} className="menus-item">
                        <div
                          className="menus-item-title"
                          onClick={() => {
                            switchPage(v.path, true)
                          }}
                        >
                          {v.name}
                        </div>
                        {v.children &&
                          v.children.length > 0 &&
                          v.children.map((v1, key1) => {
                            return (
                              <div
                                key={key1}
                                className="menus-item-tab"
                                onClick={() => {
                                  switchPage(v1.path, true)
                                }}
                              >
                                {v1.name}
                              </div>
                            )
                          })}
                      </div>
                    )
                  })}
              </div>
              <img
                src={Close}
                className="header-main-menus-close"
                onClick={() => {
                  setIsMenuMoreShow(!isMenuMoreShow)
                }}
              />
            </div>
          </div>
          <div className="header-main-menus-mobile">
            <div className="header-main-menus-mobile-content">
              <div className="menus-mobile-l">
                <img
                  src={LogoWhite}
                  alt=""
                  className="menus-mobile-l-logo"
                  onClick={() => {
                    goPage('/')
                  }}
                />
                {nav_menus &&
                  nav_menus.length > 0 &&
                  nav_menus.map((v, key) => {
                    return (
                      <div
                        key={key}
                        className={
                          v?.id === mobileMenuSel.id
                            ? 'menus-mobile-l-item-active'
                            : 'menus-mobile-l-item'
                        }
                        onClick={() => {
                          setMobileMenuSel(v)
                        }}
                      >
                        {v?.name}
                      </div>
                    )
                  })}
              </div>
              <div className="menus-mobile-r">
                <img
                  src={CloseBlack}
                  alt=""
                  className="menus-mobile-r-close"
                  onClick={() => {
                    setIsMenuMoreShow(!isMenuMoreShow)
                  }}
                />
                <div className="menus-mobile-r-child2">
                  <div
                    onClick={() => {
                      switchPage(mobileMenuSel?.path, true)
                    }}
                    style={{ fontWeight: 700 }}
                  >
                    {mobileMenuSel?.name}
                  </div>
                  {mobileMenuSel?.children &&
                    mobileMenuSel?.children.length > 0 &&
                    mobileMenuSel?.children.map((v, key) => {
                      return (
                        <div
                          key={key}
                          onClick={() => {
                            switchPage(v?.path, true)
                          }}
                        >
                          ·{v?.name}
                        </div>
                      )
                    })}
                </div>
                {/* {mobileMenuSel?.children &&
                  mobileMenuSel?.children.length > 0 &&
                  mobileMenuSel?.children.map((v, key) => {
                    return (
                      <div
                        key={key}
                        className="menus-mobile-r-child2"
                        onClick={() => {
                          switchPage(v?.path, true)
                        }}
                      >
                        {v?.name}
                        {v?.children &&
                          v?.children.length > 0 &&
                          v?.children.map((v1, key1) => {
                            return <div key={key1}>·{v1?.name}</div>
                          })}
                      </div>
                    )
                  })} */}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Header
