import React, { Suspense, useEffect, useState } from 'react'
import { Route, useLocation } from 'react-router-dom'
import AppContext from '@/context'
import api from '@/api'
import Header from './Header/Header'
import Bottom from './Bottom/Bottom'

import './MainLayout.scss'

const MainLayout = (props) => {
  const { routes } = props
  const location = useLocation()
  const [wrapClassName, setWrapClassName] = useState('')

  const [appData, setAppData] = useState({})

  const initData = () => {
    api.post('api/common/init').then((res) => {
      setAppData(res || {})
      localStorage.setItem('appData', JSON.stringify(res))
    })
  }

  useEffect(() => {
    const pathName = location.pathname || ''
    const pathArr = pathName.split('/') || []
    const catPath = pathArr[2] || 'default'
    const pageClass = pathName.replace(/\//g, '-')

    if (location.search.indexOf('ap' === -1)) {
      // 解决锚点定位与页面滚动冲突
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    //给页面加上页面专属类名
    setWrapClassName(
      `common-wrap ${catPath}-layout-wrap page${pageClass}-layout-wrap`
    )
  }, [location.pathname])

  useEffect(() => {
    initData()
  }, [])

  return (
    <div className={`${wrapClassName}`}>
      <Header></Header>
      <div className="body-wrap">
        <Suspense fallback={null}>
          {routes &&
            routes.map((route) => (
              <Route
                component={route?.component}
                exact
                key={route?.path}
                path={`/main/${route?.path}`}
              ></Route>
            ))}
        </Suspense>
      </div>
      <div className="footer-wrap">
        {/* 底部 */}
        <Bottom></Bottom>
      </div>
      {/* 头部 */}
    </div>
  )
}
export default MainLayout
