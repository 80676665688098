import React from 'react'

import './CardItem.scss'

const CardItem = (props) => {
  const itemData = props?.data || {}

  const moreClick = () => {
    props.moreClick && props.moreClick()
  }

  return (
    <div className="carditem-container">
      <div className="carditem-container-img-container">
        <img
          src={itemData?.image}
          className="carditem-container-img hoverScale"
          onClick={moreClick}
        />
      </div>

      <div className="carditem-container-time">{itemData?.create_time}</div>
      <div className="carditem-container-content singleLine2">
        {itemData?.desc}
      </div>
      <div className="carditem-container-more" onClick={moreClick}>
        {'了解更多  >'}
      </div>
    </div>
  )
}

export default CardItem
