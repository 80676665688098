import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
// 语言包
import en from './locales/en-US.json'
import cn from './locales/zh-CN.json'

// antd
import { setDefaultConfig } from 'antd-mobile/es/components/config-provider'
import enUS from 'antd-mobile/es/locales/en-US'
import zhCN from 'antd-mobile/es/locales/zh-CN'
const antLocalesMap = {
  'en': enUS,
  'zh-CN': zhCN,
}

// dayjs
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)
import dayjsZhCN from 'dayjs/locale/zh-cn'
import dayjsEn from 'dayjs/locale/en'

const dayjsLocalesMap = {
  'en': dayjsEn,
  'zh-CN': dayjsZhCN,
}


const resources = {
  'zh-CN': {
    translation: cn,
  },
  'en': {
    translation: en,
  },
}

const i18niInt = (lang)=> {
  i18n.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'zh-CN',
    preload: ['en', 'zh-CN'],
    detection: {
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    // keySeparator: false, // we do not use keys in form messages.welcome
    react: {
      useSuspense: false,
    },
  })
  lang && i18n.changeLanguage(lang)
  // antD国际化
  setDefaultConfig({
    locale: antLocalesMap[i18n.language],
  })
  // dayjs国际化
  dayjs.locale(dayjsLocalesMap[i18n.language])
}


export default i18niInt