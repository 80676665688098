// 使用fundebug
const env = process.env.NODE_ENV

// 初始化fundebug
const fundebugInit = () => {
  if (env !== 'development' && 'fundebug' in window) {
    fundebug.init({
      appversion: process.env.REACT_APP_VERSION,
      apikey: fundebug.apikey,
      monitorHttpBody:true,
      monitorHttpResponse: true,
    })
  }
}

// 错误通知fundebug
const fundebugNotifyError = (error, info) => {
  if (env !== 'development' && 'fundebug' in window) {
    fundebug.notifyError(error, {
      metaData: {
        info: info,
      },
    })
  }
}

export { fundebugInit, fundebugNotifyError }
