import axios from 'axios'
import i18n from 'i18next'
import { Toast } from 'antd-mobile'
import queryString from 'query-string'
import { getUser, tagTester, isType, sniff } from '@/util'
import { Loading } from '@/components'
import { fundebugNotifyError } from '@/util/fundebugUse'
import Env from '@/Api/domain'
import { history } from '@/util'

Env.set()

export default class Server {
  axios(method, url, payload, config = { structured: false }) {
    return new Promise((resolve, reject) => {
      if (!isType.isObject(payload) && tagTester('Object')(payload) === false)
        payload = {}
      const search = queryString.parse(history.location.search) || {}

      const _config = config
      const token = getUser().token
      const currentLanguage = i18n.language // 当前语言
      const _option = {
        baseURL: `${Env.getApiBase()}/`,
        method,
        url,
        timeout: 30000,
        params: null,
        data: null,
        headers: {
          // 'x-client-platform': sniff.isMobile ? 'mobile' : 'pc', // 支付用
          // 'x-client-source': search?.source , // 来源
          // 'x-client-lang': currentLanguage,
          'x-client-device': sniff.isMobile ? 'mobile' : 'pc', // 设备
          // 'x-client-ua': navigator.userAgent,
          'Content-Type': 'application/json',
          // token: sniff.isMobile ? 'mobile' : '',
          // Authorization: 'Bearer ' + token,
        },
        transformRequest: [
          function (data) {
            let param
            if (tagTester('File')(data?.filePath)) {
              param = new FormData()
              param.append('file', data.filePath)
            } else {
              param = JSON.stringify(data)
            }
            return param
          },
        ],
        ...payload,
      }
      _config.showLoading &&
        Loading.show({
          duration: _config.timeout || 0,
        })
      try {
        axios.request(_option).then(
          (res) => {
            _config.showLoading && Loading.close()
            let resolveData = res.data
            if (_config.structured) {
              resolveData = res.data.data || res.data || {}
            }
            if (res.data?.status === 'fail') {
              const bussinesError = JSON.stringify(res.data.error)
              if (!_config.hideFailToast) {
                Toast.show({
                  content: res.data?.error?.message || '系统异常', // 默认2s
                })
              }
              fundebugNotifyError(bussinesError, bussinesError)
            } else {
              // 设置网页关键字、描述词
              const seo_title = resolveData?.seo_title || resolveData?.site_info?.seo_title
              // const seo_keywords = resolveData?.seo_keywords || resolveData?.site_info?.seo_keywords
              const seo_desc = resolveData?.seo_desc || resolveData?.site_info?.seo_desc
              // document.title = seo_title || '泛思迈'
              if (seo_title) {
                document.querySelector('#metakeywords').setAttribute('content', seo_title || '泛思迈')
              }
              if (seo_desc) {
                document.querySelector('#metadescription').setAttribute('content', seo_desc || '泛思迈')
              }
            }

            resolve(resolveData)
          },
          (error) => {
            _config.showLoading && Loading.close()
            if (error.response) {
              reject(error.response.data)
            } else {
              reject(error)
            }
          }
        )
      } catch (error) {
        console.log(error)
      }
    })
  }
}
