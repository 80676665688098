import { history } from '@/util'
import queryString from 'query-string'

const goPage = function (url = '', isReplace) {
  const searchObj = queryString.parse(history?.location?.search)
  const urlObj = queryString.parseUrl(url)
  let fixedUrl = url

  const searchStr = queryString.stringify({
    ...urlObj.query,
    source: searchObj?.source,
  })

  fixedUrl = `${urlObj?.url}?${searchStr}`

  if ( isReplace ) {
    history.replace(`${fixedUrl}`)
  } else {
    history.push(`${fixedUrl}`)
  }
}

export default goPage