const ua = navigator.userAgent.toLocaleLowerCase() || ''

const isAndroid = /android/i.test(ua)

const isIos = /iphone|ipad|ipod/i.test(ua)

const getIOSVersion = () => {
  if (isIos) {
    const version = ua.match(/os (\d+)_(\d+)_?(\d+)?/) as string[]
    return Number.parseInt(version[1], 10)
  }
}

const isMobile =
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
    ua
  )

const IOSVersion = getIOSVersion()

// 是否iphonx及以上刘海屏手机
const ioIPhoneX = window.screen.height >= 812 && window.devicePixelRatio >= 2

// 微信中打开
const isWechat = /micromessenger\/([\d.]+)/i.test(ua)

// 微信小程序中打开
const isWechatMiniProgram = /miniprogram/.test(ua)

// QQ中打开
const isQQ = /qq\/([\d.]+)/i.test(ua)

// 区分是否是移动端
const isPhone =
  /phone|pad|pod|iphone|ipod|ios|ipad|android|mobile|blackberry|iemobile|mqqbrowser|juc|fennec|wosbrowser|browserng|webos|symbian|windows phone/i.test(
    ua
  )

/**
 * 获取当前执行环境
 *
 * @return string
 */
const getPlatformType = () => {
  switch (true) {
    case isQQ:
      return 'qq'
    case isWechat:
      return 'wechat'
    default:
      return 'brower'
  }
}

const platformType = getPlatformType()

export default {
  isMobile,
  isAndroid,
  isIos,
  IOSVersion,
  ioIPhoneX,
  isWechat,
  isWechatMiniProgram,
  isQQ,
  isPhone,
  type: platformType,
}
