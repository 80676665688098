import queryString from 'query-string'

class Env {
  static domainList = {
    dev: {
      base: 'http://www.vansmine.com/',
    },
    prod: {
      base: 'http://www.vansmine.com/',
    },
  }
  // 获取环境
  static get() {
    const searchObj = queryString.parse(location.search) ?? {}
    // App内直接获取localStorage中的env
    return searchObj.subenv === 'dev' ? 'dev' : 'prod'
  }
  // 获取接口域名地址
  static getApiBase() {
    const env = `${Env.get()}`.toLowerCase() || 'prod'
    return Env.domainList[env]?.base
  }
  // 将url中的环境设置到localStorage
  static set() {
    // 清除之前的老的localStorage
    localStorage.removeItem('domain')
    const searchObj = queryString.parse(location.search) ?? {}
    const subEnv = searchObj.subenv
    subEnv && (localStorage.env = subEnv)
  }
}

export default Env
