export const isUndefined = (u: any): u is undefined => {
  return typeof u === 'undefined'
}

export const isString = (s: any): s is string => {
  return typeof s === 'string'
}

export const isNumber = (s: any): s is number => {
  return typeof s === 'number'
}

export const isBoolean = (b: any): b is boolean => {
  return typeof b === 'boolean'
}

export const isFunction = (f: any): f is Function => {
  return typeof f === 'function'
}

export const isObject = (o: any): o is object => {
  return typeof o === 'object'
}

export const isPromise = <T>(p: any): p is Promise<T> => {
  return p instanceof Promise
}
