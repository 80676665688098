import React, { useEffect, useRef } from 'react'
import { sniff } from '@/util'

import './login.scss'

const Login = () => {
  const loginWrapRef = useRef(null)

  const setWrapHeight = () => {
    loginWrapRef.current.style.height = document.documentElement.clientHeight + 'px'
  }

  useEffect(() => {
    if (!sniff.isMobile) {
      setWrapHeight()
      window.addEventListener('resize', setWrapHeight)
      return () => window.removeEventListener('resize', setWrapHeight)
    }
  }, [])

  return (
    <div className="login-outer-wrap" ref={loginWrapRef}>
      登陆页面
    </div>
  )
}

export default Login
