import querystring from 'query-string'
import { sniff } from '@/util'
/**
 * pageName 页面名字
 */
const scrollToAp = (pageName, id) => {
  const distance = sniff.isMobile ? 48 : 96
  if (location.href.indexOf('?') === -1) return

  const _href = location.href.split('?')[1]
  const search = querystring.parse(_href) || {}
  if (search.ap !== undefined) {
    let timer = null
    timer = setInterval(() => {
      const scrDom = document.querySelector(`#${pageName}-${id || search.ap}`)
      if (scrDom) {
        window.scrollTo({
          top: scrDom.offsetTop - distance,
          behavior: 'smooth',
        })
        clearInterval(timer)
      }
    }, 500)
  }
}

export default scrollToAp
