import { Toast } from 'antd-mobile'
import React, { useEffect, useState } from 'react'

const loadingDefaultPic = ''
const loadingWhitePic = ''

// 样式
import style from './Loading.module.scss'

let Loading: any

const LoadingContent = () => {
  const [picUrl, setPicUrl] = useState(loadingDefaultPic)
  useEffect(() => {
    // 加载成功后替换成gif地址
    const _pic = new Image()
    _pic.src = loadingWhitePic
    _pic.onload = () => {
      setPicUrl(loadingWhitePic)
    }
  }, [])
  return (
    <>
      <img alt="0" className={style.pic} src={picUrl} />
    </>
  )
}

const LoadingShow = (props: any) => {
  Toast.config({ duration: 0 })
  // 关闭所有toast
  Toast.clear()
  Loading = Toast.show({
    maskClassName: style.loading,
    maskClickable: true,
    icon: <LoadingContent></LoadingContent>,
    ...props,
  })
}

const LoadingClose = () => {
  Loading.close()
  Toast.config({ duration: 2000 })
}

export default {
  show: LoadingShow,
  close: LoadingClose,
}
