import { createBrowserHistory, createHashHistory } from 'history'

const forceRefreshHistory = createBrowserHistory({
  forceRefresh: true,
})

// const createHistory = createBrowserHistory()
const createHistory = createHashHistory()

export { forceRefreshHistory, createHistory }
