import { lazy } from 'react'

export const homeRouter = [
  {
    component: lazy(() => import('@/pages/home')),
    path: 'index',
  },
  {
    component: lazy(() => import('@/pages/about')),
    path: 'about',
  },
  {
    component: lazy(() => import('@/pages/manufacture')),
    path: 'manufacture',
  },
  {
    component: lazy(() => import('@/pages/material')),
    path: 'material',
  },
  {
    component: lazy(() => import('@/pages/news')),
    path: 'news',
  },
  {
    component: lazy(() => import('@/pages/detail-news')),
    path: 'detail-news',
  },
  {
    component: lazy(() => import('@/pages/recruit')),
    path: 'recruit',
  },
  {
    component: lazy(() => import('@/pages/detail-recruit')),
    path: 'detail-recruit',
  },
  {
    component: lazy(() => import('@/pages/support')),
    path: 'support',
  },
  {
    component: lazy(() => import('@/pages/detail-product')),
    path: 'detail-product',
  },
  {
    component: lazy(() => import('@/pages/detail-product-new')),
    path: 'detail-product-new',
  },
]
