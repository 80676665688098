export type UserStoreType = {
  token: string,
  user: {
      id: number,
      avatar?: string,
      mobile?: string, // 中间四位****脱敏处理的手机号
      name?: string,
      socket_id: string,
   },
   loginTime?: number // 非App端登录时间
}
const defaultUserStore: UserStoreType = {
  'token': '',
  'user': {
    'id' : 0,
    'socket_id': '',
  },
}
class UserStore {
  static userLocalStorageKey = 'USER'

  static phoneNumberMask(mobile: string | undefined): string {
    const mobileString = mobile + ''
    const fixedMobile = mobileString.replace(/^(\d{3})\d{4}(\d{4})$/,'$1****$2')
    return fixedMobile
  }
  /**
   *
   *
   * @param {UserStoreType} 新版本存储的用户数据
   * @memberof UserStore
   */
  setUser( value: UserStoreType ): void {
    const { user } = value
    let userStorage = ''
    // 验码处理
    if ( user?.mobile ) {
      user.mobile = UserStore.phoneNumberMask(user.mobile)
    }
    try {
      userStorage = JSON.stringify(value)
    } catch (error) {
      userStorage = ''
    }
    localStorage.setItem(UserStore.userLocalStorageKey,userStorage)
  }
  /**
   *
   *
   * @param {UserStoreType} 老版本存储的用户数据
   * @memberof UserStore
   */
  setOldUser( value: string ): void {
    localStorage.setItem('token',value)
  }
  /**
   *
   *
   * @return {*}  将用户数据存到localStorage
   * @memberof UserStore
   */
  getUser(): object {
    // 兼容ios 1.8.0 user是小写
    const userStorage = localStorage.getItem(UserStore.userLocalStorageKey) || localStorage.getItem(UserStore.userLocalStorageKey.toLowerCase()) || `${JSON.stringify(defaultUserStore)}`
    let userObj
    try {
      userObj = JSON.parse(userStorage)
    } catch (error) {
      userObj = defaultUserStore
    }
    // 计算时间差
    const token = userObj?.token
    return {
      token: token,
      user: userObj?.user || userObj, // 兼容IOS和安卓格式不一样
      // loginTime: userObj?.loginTime,
    }
  }
  // 登出
  removeUser(): void {
    localStorage.removeItem('USER')
  }
}
export default UserStore