import React from 'react'
import { goPage } from '@/util'
import './Bottom.scss'

const Bottom = () => {
  const appData = JSON.parse(localStorage.getItem('appData')) || {}
  const { nav_menus = [], contacts = [], copyright = [] } = appData

  return (
    <div className="bottom-container">
      <div className="bottom-container-main">
        <div className="bottom-tabs">
          {nav_menus &&
            nav_menus.length > 0 &&
            nav_menus.map((v, key) => {
              return (
                <div key={key} className="bottom-tab-item cp">
                  <div
                    className="bottom-tab-item-title"
                    onClick={() => {
                      goPage('/main' + v.path)
                    }}
                  >
                    {v?.name}
                  </div>
                  {v.children &&
                    v.children.length > 0 &&
                    v.children.map((v1, key1) => {
                      return (
                        <div
                          key={key1}
                          className="bottom-tab-item-subtab"
                          onClick={() => {
                            goPage('/main' + v1.path)
                          }}
                        >
                          {v1.name}
                        </div>
                      )
                    })}
                </div>
              )
            })}
        </div>
        <div className="bottom-contacts">
          {contacts.length > 0 && (
            <>
              <div className="bottom-contacts-item">
                <div>
                  {contacts[0]?.name}: {contacts[0]?.value}
                </div>
                <div>
                  {contacts[1]?.name}: {contacts[1]?.value}
                </div>
              </div>
              <div className="bottom-contacts-item">
                <div>
                  {contacts[2]?.name}: {contacts[2]?.value}
                </div>
                <div>
                  {contacts[3]?.name}: {contacts[3]?.value}
                </div>
              </div>
            </>
          )}

          {copyright.length > 0 && (
            <div className="bottom-contacts-item cp">
              {copyright.map((v, key) => {
                return (
                  <div
                    key={key}
                    onClick={() => {
                      if (!v?.link) return
                      window.open(v?.link)
                    }}
                  >
                    {v?.key}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
      <div className="bottom-container-main-mobile">
        <p>
          {contacts[0]?.name}: {contacts[0]?.value}
        </p>
        <p>
          {contacts[1]?.name}: {contacts[1]?.value}
        </p>
        <p>
          {contacts[2]?.name}: {contacts[2]?.value}
        </p>
        <p>
          {contacts[3]?.name}: {contacts[3]?.value}
        </p>

        {copyright.length > 0 && (
          <div className="bottom-contacts-item" style={{ marginTop: 20 }}>
            {copyright.map((v, key) => {
              return (
                <p
                  key={key}
                  onClick={() => {
                    if (!v?.link) return
                    window.open(v?.link)
                  }}
                >
                  {v?.key}
                </p>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default Bottom
