import React from 'react'

import { Swiper } from '@/components'
import { sniff } from '@/util'

import './MainTop.scss'

const MainTop = (props) => {
  const {
    tabList = [],
    page = '',
    banner = [],
    banner_video = '',
    hoverScale = false,
  } = props

  const bannerList = typeof banner === 'string' ? [{ image: banner }] : banner

  const scrollToDom = (id) => {
    const distance = sniff.isMobile ? 48 : 96
    const rollDom = document.querySelector(`#${page}-${id}`)
    window.scrollTo({ top: rollDom.offsetTop - distance, behavior: 'smooth' })
  }

  return (
    <div className="top-container">
      <div className="top-container-swiper">
        {bannerList.length > 0 && (
          <Swiper
            imgList={bannerList}
            banner_video={banner_video}
            hoverScale={hoverScale}
          ></Swiper>
        )}
      </div>
      <div className="top-container-tab">
        {tabList.length > 0 &&
          tabList.map((v, key) => {
            return (
              <div
                key={key}
                className="top-container-tab-item cp"
                onClick={() => {
                  scrollToDom(v?.slug)
                }}
              >
                {v?.name}
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default MainTop
